
const bunqBaseURL = 'https://bunq.me/chairity'

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-show]').forEach((e) => {
    e.addEventListener('click', () => {
      document.querySelector(e.dataset.show).classList.add('show')
      document.getElementById('name').focus()
    })
  })
  document.querySelectorAll('[data-hide]').forEach((e) => {
    e.addEventListener('click', () => {
      document.querySelector(e.dataset.hide).classList.remove('show')
    })
  })

  const form = document.getElementById('donate')
  form.addEventListener('submit', e => {
    e.preventDefault()
    const amount = document.getElementById('amount').value
    const name = document.getElementById('name').value
    const url = `${bunqBaseURL}/${amount}/${encodeURI(name)}`
    window.location.href = url
  })
})
